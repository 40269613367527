<template>
  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :customer-obj="customerData"
      @refetch-data="refetchData"
    />
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>Bilgilendirme:</strong>Firmaya yetkili eklediğinizde aşağıdaki kaydet butonuna basmanıza gerek kalmaksızın eklenecektir. Firmaya ait diğer bilgileri güncellediğinizde kaydet butonuna basmanız gerekmektedir</span>
      </div>
    </b-alert>
    <b-row>

      <b-col md="6">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            v-if="customerData"
            class="p-2"

            @submit.prevent="handleSubmit(saveData)"
            @reset.prevent="resetForm"
          >
            <b-form-group
              :label="$t('Firma Aktif')"
              label-for="is_active"
            >
              <b-form-checkbox
                v-model="customerData.is_active"
                class="custom-control-primary"
                name="check-button"
                switch
                :value="1"
                :unchecked-value="0"
              />
            </b-form-group>
            <validation-provider
              #default="validationContext"
              :name="$t('Cari Kodu')"
              rules="required"
            >
              <b-form-group
                :label="$t('Cari Kodu')"
                label-for="uyum_code"
              >
                <b-form-input
                  id="uyum_code"
                  v-model="customerData.uyum_code"
                  autofocus
                  :readonly="customerData.creator!=1"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Firma Adı')"
              rules="required|min:2"
            >
              <b-form-group
                :label="$t('Firma Adı')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="customerData.name"
                  autofocus
                  :readonly="customerData.creator!=1"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Firma ad -->

            <!-- Web sitesi -->
            <validation-provider
              #default="validationContext"
              :name="$t('Firma Web Adresi')"
              rules="min:3"
            >
              <b-form-group
                :label="$t('Firma Web Adresi')"
                label-for="website"
              >
                <b-form-input
                  id="website"
                  v-model="customerData.website"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Ülke -->
            <b-form-group
              v-if="countryOptions"
              :label="$t('Ülke')"
              label-for="country"
            >
              <v-select
                id="country"
                v-model="customerData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="country"
              />
            </b-form-group>
            <!-- Ülke -->
            <b-form-group
              v-if="priceGroupOptions"
              :label="$t('Fiyat Grubu')"
              label-for="price_group"
            >
              <v-select
                id="price_group"
                v-model="customerData.price_group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="priceGroupOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="price_group"
              />
            </b-form-group>
            <!-- Şehir -->
            <validation-provider
              #default="validationContext"
              :name="$t('Şehir')"
              rules="min:3"
            >
              <b-form-group
                :label="$t('Şehir')"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="customerData.city"
                  :readonly="customerData.creator!=1"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Satış Temsilcisi -->
            <b-form-group
              v-if="usersData"
              :label="$t('Satış Temsilcisi')"
              label-for="user_id"
            >
              <v-select
                id="user_id"
                v-model="customerData.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersData"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user_id"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_black_list"
                v-model="customerData.is_black_list"
                class="custom-control-danger"
                name="check-button"
                :disabled="customerData.creator!=1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('Kara Listeye Dahil Et') }}
              </b-form-checkbox>

            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_potential"
                v-model="customerData.is_only_mailorder"
                class="custom-control-warning"
                name="check-button"

                :value="1"
                :unchecked-value="0"
              >
                {{ $t('Sadece Mail Order Yapabilsin') }}
              </b-form-checkbox>

            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
            </div>
          </b-form>

        </validation-observer>
      </b-col>
      <b-col
        md="6"
      >

        <b-list-group-item
          v-for="(item,index) in customerData.addresses"

          :key="index"
          tag="li"
          :variant="item.title==''?'danger':''"
          class="d-flex border rounded"
        >

          <b-row class="flex-grow-1 p-2">

            <b-col md="6">
              <b-form-checkbox
                v-model="item.is_invoice"
                :value="1"
                :unchecked-value="0"
                name="checkbox-input"
                @change="selectInvoiceAddress(item)"
              >
                {{ $t('Varsayılan Fatura Adresi') }}
              </b-form-checkbox>
            </b-col>   <b-col md="6">
              <b-form-checkbox
                v-model="item.is_shipping"
                :value="1"
                :unchecked-value="0"
                name="checkbox-input"
                @change="selectShippingAddress(item)"
              >
                {{ $t('Varsayılan Kargo Adresi') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="6">
              <b-form-input
                v-model="item.city"
                :placeholder="$t('Şehir')"
              />

            </b-col>
            <b-col md="6">
              <b-form-input
                v-model="item.district"
                :placeholder="$t('İlçe/Semt')"
              />

            </b-col>
            <b-col md="12">
              <b-form-input
                v-model="item.postal_code"
                :placeholder="$t('Posta Kodu')"
              />

            </b-col>
            <b-col md="12">
              <b-form-textarea
                v-model="item.address"
                rows="2"
                :placeholder="$t('Adres Giriniz')"
              />
            </b-col>

          </b-row>

          <div class="d-flex flex-column justify-content-between border-left py-50 px-25 danger">
            <feather-icon
              size="16"
              icon="XIcon"
              class="cursor-pointer label-danger"
              @click="deleteAddress(index)"
            />

          </div>

        </b-list-group-item>
        <hr>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"

            variant="flat-primary"
            @click="addNewAddress"
          >
            <feather-icon icon="EditIcon" />
            {{ $t('Adres Ekle') }}
          </b-button>
        </div>
        <hr>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"

            variant="flat-primary"
            @click="isAddNewUserSidebarActive = true"
          >
            <feather-icon icon="EditIcon" />
            {{ $t('Yeni Kullanıcı Ekle') }}
          </b-button>
        </div>
        <b-list-group v-if="customerUsers">
          <b-list-group-item
            v-for="(item) in customerUsers"
            :id="item.id"
            :key="item.id"
            class="d-flex justify-content-between align-items-center"
          >
            <span>{{ item.label }}  </span>
            <div>
              <b-dropdown
                variant="link"
                no-caret
                size="sm"
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item :to="{ name: 'users-edit', params: { id: item.value } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                </b-dropdown-item>

              </b-dropdown></div>

          </b-list-group-item>
        </b-list-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BFormTextarea, BButton, BFormCheckbox, BAlert, BListGroupItem, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import UserListAddNew from '../../user/users-list/UserListAddNew.vue'
import userStoreModule from '../../user/userStoreModule'

export default {
  components: {
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BRow,
    UserListAddNew,
    BCol,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup,
    BListGroupItem,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditCustomerSidebarActive',
    event: 'update:is-edit-customer-sidebar-active',
  },
  props: {
    blankCustomerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,

    }
  },
  watch: {
  },
  methods: {
    deleteAddress(index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu müşteri adresini silmek istiyor musunuz ?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.customerData.addresses.splice(index, 1)
        }
      })
    },
    selectInvoiceAddress(item) {
      this.customerData.addresses.forEach(val => {
        // eslint-disable-next-line eqeqeq
        if (val != item && item.is_invoice) {
          // eslint-disable-next-line no-param-reassign
          val.is_invoice = 0
        }
      })
    },
    selectShippingAddress(item) {
      this.customerData.addresses.forEach(val => {
        // eslint-disable-next-line eqeqeq
        if (val != item && item.is_shipping) {
          // eslint-disable-next-line no-param-reassign
          val.is_shipping = 0
        }
      })
    },
    addNewAddress() {
      this.customerData.addresses.push(JSON.parse(JSON.stringify(this.blankAddressData)))
    },
    addNewUser() {
      this.customerData.addresses.push(JSON.parse(JSON.stringify(this.blankAddressData)))
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeCustomerData() {
      this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
    },
    saveData() {
      store.dispatch('app-customer/updateCustomer', this.customerData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // fetch user
    const countryOptions = ref(null)
    const priceGroupOptions = ref(null)
    const customerData = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const blankAddressData = {
      id: 0,
      address: '',
      city: '',
      postal_code: '',
      is_shipping: 0,
      is_invoice: 0,
      phone: '',
      district: '',
    }
    store.dispatch('app/fetchCountries', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.code }))
        countryOptions.value = arr
      })

    store.dispatch('app/fetchPriceGroups', { list_type: 'all' })
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.id }))
        priceGroupOptions.value = arr
      })
    const usersData = ref(null)
    const customerUsers = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'only_representative',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })

    const onSubmit = () => {

    }
    const resetcustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(props.blankCustomerData))
    }
    resetcustomerData()
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
        company_id: customerData.value.id,
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        customerUsers.value = arr
      })
      .catch(() => {
      })
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetcustomerData)

    return {
      onSubmit,
      countryOptions,
      isAddNewUserSidebarActive,
      customerUsers,
      priceGroupOptions,
      refFormObserver,
      getValidationState,
      usersData,
      customerData,
      blankAddressData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
